<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'part-create create-form'"
    v-if="getPermission('part:create') || getPermission('part:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        <template v-if="partId">Update</template>
        <template v-else>Create</template> Part
      </h1>
    </template>
    <template v-slot:header-action>
      <div>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button text-white"
          color="cyan"
          v-on:click="onSubmit"
        >
          <template v-if="partId">Update</template>
          <template v-else>Save</template> Part
        </v-btn>
        <v-btn
          v-on:click="goBack"
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          outlined
          >Cancel
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <div class="part-create create-form" v-if="getPermission('part:create')">
        <v-form
          ref="partForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="onSubmit"
        >
          <v-container fluid class="white-background">
            <PartCreate
              :partId="partId"
              :barcodeData="barcode"
              :updateMode="updateMode"
              :partCreateData="partCreate"
              v-on:savePart="updatePart"
            ></PartCreate>
          </v-container>
        </v-form>
      </div>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductMixin from "@/core/plugins/product-mixin";
import { ProductBrandEventBus } from "@/core/lib/product.brand.lib";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";
import PartCreate from "@/view/pages/part/partials/Create.vue";
//import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

export default {
  mixins: [CommonMixin, ProductMixin],
  name: "part-create",
  title: "Create Part",
  data() {
    return {
      formValid: true,
      partCreated: false,
      barcode: null,
      barcodeDialog: false,
      updateMode: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      parttCreate: null,
      partCreate: null,
      partId: null,
      duplicateId: null,
      routePreventDialog: false,
    };
  },
  components: {
    PartCreate,
    CreateUpdateTemplate,
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    updatePart(param) {
      this.partCreate = param;
    },
    getPart(partId) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (partId && partId > 0) {
          _this.$store
            .dispatch(GET, { url: "part/" + partId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },

    onSubmit() {
      const _this = this;
      if (!_this.$refs.partForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "part";

      if (_this.partId && _this.partId > 0) {
        requestType = PUT;
        requestURL = "part/" + _this.partId;
      }

      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.partCreate })
        .then(async (response) => {
          _this.partId = response.data ? response.data.id : null;
          _this.partCreated = true;
          _this.$router.push(
            _this.getDefaultRoute("part", {
              query: {
                status: "all",
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getPart(_this.partId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Part", route: "part" },
              { title: "Update" },
            ]);

            _this.partCreate = {
              name: response.name,
              barcode: response.barcode ? response.barcode : null,
              gds_code: response.gds_code ? response.gds_code : null,
              description: response.description ? response.description : null,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,
              unit: response.unit ? response.unit : null,
              initial_stock: response.initial_stock,
              length: response.length,
              weight: response.weight,
              height: response.height,

              length_unit: response.length_unit,
              weight_unit: response.weight_unit,
              height_unit: response.height_unit,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    loadDuplicateContent() {
      const _this = this;
      _this
        .getPart(_this.duplicateId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.partCreate = {
              name: response.name,
              //barcode: this.barcode,
              gds_code: response.gds_code ? response.gds_code : null,
              description: response.description ? response.description : null,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,
              unit: response.unit ? response.unit : null,
              initial_stock: response.initial_stock,
              length: response.length,
              weight: response.weight,
              height: response.height,

              length_unit: response.length_unit,
              weight_unit: response.weight_unit,
              height_unit: response.height_unit,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  created() {
    const _this = this;
    _this.partId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Part", route: "part" },
      { title: "Create" },
    ]);

    if (
      _this.$route.name == "admin.part.update" ||
      _this.$route.name == "engineer.part.update"
    ) {
      _this.updateMode = true;

      if (_this.partId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }

    ProductBrandEventBus.$on("refress-brand", function () {
      this.getPart(_this.partId);
    });
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.partCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
